import clsx from 'clsx';
import { subscribe, unsubscribe } from 'pubsub-js';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ease = 'cubic-bezier(.2,0,.2,1)';
const Container = styled.div`
  position: fixed;
  inset: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.35s ${ease};
  will-change: opacity;
  backdrop-filter: blur(50px) contrast(110%);
  z-index: 251;

  backdrop-filter: blur(50px);
  .bg {
    background-color: var(--color-primary-20);
  }
  // @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  @supports not (backdrop-filter: blur(50px)) {
    .bg {
      background-color: var(--color-primary-50);
    }
  }

  .outer {
    height: 100vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  /* .outer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  } */
  &.is-placed {
    opacity: 1;
    pointer-events: all;
    /* cursor: zoom-out; */
    cursor: url("data:image/svg+xml,%3Csvg width='23' height='24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpolygon points='1.4,23.3 0,21.9 9.9,12 0,2.1 1.4,0.7 11.3,10.6 21.2,0.7 22.6,2.1 12.7,12 22.6,21.9 21.2,23.3 11.3,13.4 '/%3E%3C/svg%3E")
        18 18,
      zoom-out;
  }
  &.is-anime .outer {
    /* transition: width 0.5s ${ease}, height 0.5s ${ease}, transform 0.5s ${ease}; */
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 80vw;
    max-height: 80vh;
  }
  @media all and (max-width: 1080px) {
    max-width: 100vw;
    .container {
      max-width: none;
      margin: 0;
    }
    figure {
      width: 100vw;
    }
    img {
      width: 100vw;
      max-width: unset;
      max-height: 80vh;
    }
    figcaption {
      padding-left: 1em;
    }
  }
`;
const ImageExpand = ({ selector }) => {
  // console.log(selector);
  const [image, setImage] = useState();
  const [background, setBackground] = useState('white');
  const [aspectRatio, setAspectRatio] = useState('1 / 1');
  // const initialBounding = { x: 0, y: 0, width: 0, height: 0 };
  // const [bounding, setBounding] = useState(initialBounding);
  const ref = useRef();
  const outerRef = useRef();
  // const innerRef = useRef()

  useEffect(() => {
    let btns;
    const token = subscribe('ROUTE_UPDATE', () => {
      setTimeout(() => {
        btns = document.querySelectorAll(selector);
        // console.log(btns);
        if (btns) {
          btns.forEach((el) => {
            // console.log(el);
            el.removeEventListener('click', _placeIt);
            el.addEventListener('click', _placeIt);
          });
        }
      }, 250);
    });

    return () => {
      if (btns)
        btns.forEach((el) => {
          el.removeEventListener('click', _placeIt);
        });

      unsubscribe(token);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector]);

  const _placeIt = ({ target }) => {
    // console.log(target);
    if (!target) return;
    // console.log(target.getBoundingClientRect());
    // const { width, height } = target.getBoundingClientRect();
    const width = target.getAttribute('width');
    const height = target.getAttribute('height');
    // console.log(width, height);
    setAspectRatio(`${width} / ${height}`);
    // console.log(width);
    const bg = window.getComputedStyle(target).backgroundColor;
    setBackground(bg);
    // const targetBounding = target.getBoundingClientRect();
    // setBounding(targetBounding);
    // const clone = React.cloneElement(target, {}, null);
    setImage(target);
  };

  useEffect(() => {
    const container = ref.current;
    if (image) {
      // console.log(image);
      container.addEventListener('click', _close);
      container.classList.add('is-placed');
    } else {
      container.classList.remove('is-placed');
    }

    return () => {
      container.removeEventListener('click', _close);
    };
  }, [image]);

  const _close = () => {
    setImage(null);
  };
  // console.log(aspectRatio);
  return (
    <Container ref={ref} className={clsx('image-expand')}>
      <div className="bg absolute inset-0 bg-primary-50"></div>
      <div className="container">
        <div className="outer max-w-full" ref={outerRef}>
          <div className="inner max-w-full">
            {image && (
              <figure>
                <div
                  style={{
                    backgroundColor: background,
                    aspectRatio: aspectRatio,
                    // minHeight: '80vh',
                  }}
                >
                  <img src={image.src} alt={image.alt} className="opacity-0-" />
                  {/* <div dangerouslySetInnerHTML={{ __html: image }}></div> */}
                  {/* {image} */}
                </div>

                <figcaption className="text-sm font-secondary text-secondary py-1e absolute-">
                  {image.alt}
                </figcaption>
              </figure>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ImageExpand;
