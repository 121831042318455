import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
// import { _localizeText } from '../../core/utils';
import { useScroll } from '../../hooks/useScroll';
import useDeviceDetect from '../../hooks/useDeviceDetect';

const ScrollToTop = () => {
  const [hide, setHide] = useState();

  // useEffect(() => {
  //   _onScroll();
  //   window.addEventListener('scroll', _onScroll);

  //   return () => window.removeEventListener('scroll', _onScroll);
  // }, []);

  // const _onScroll = () => {
  //   // console.log(window.pageYOffset)
  //   if (window.pageYOffset < window.innerHeight) {
  //     setHide(true);
  //   } else {
  //     setHide(false);
  //   }
  // };
  const { isMobile } = useDeviceDetect();
  const { isBottom, scrollY } = useScroll();
  // console.log(isBottom, scrollY);
  useEffect(() => {
    if (isMobile) return;
    if (window.pageYOffset < window.innerHeight) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [scrollY, isMobile]);

  useEffect(() => {
    if (!isMobile) return;
    setHide(!isBottom);
  }, [isBottom, isMobile]);

  const _onClick = () => {
    // window.scrollTo(0, 0)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  //
  return (
    <button
      className={clsx(
        'scroll-to-top fixed bottom-md left-_sm md:bottom-lg md:right-md md:left-auto text-center cursor-pointer transition-all duration-300 text-sm- text-[18px] z-10',
        hide ? 'slideDown' : '',
        isBottom ? 'md:bottom-lg' : 'md:bottom-md'
      )}
      onClick={() => _onClick()}
      aria-label="scroll to top"
    >
      <i className="icon-chevron-n"></i>
    </button>
  );
};

export default ScrollToTop;
