import locales from '../../config/i18n';
import useLocale from '../contexts/LocaleWrapper';

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url);
  return decoded.substring(decoded.lastIndexOf('/') + 1);
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const _getLocale = () => {
  const { locale } = useLocale();
  return locale;
};

export function _localizeField(field) {
  // console.log(field);
  const { locale } = useLocale();
  // console.log(field);
  if (!field) return '';
  return field && field[locale] ? field[locale] : field['fr'];
}

export const _localizeText = (text) => {
  const { locale } = useLocale();
  return locales[locale] && locales[locale][text] ? locales[locale][text] : text;
};

export const _linkResolver = (node) => {
  if (!node || !node.slug) return '/';
  switch (node._type) {
    case 'projet':
      // console.log(node);
      return `/projet/${node.slug.current}`;
    case 'tag':
      // console.log(node);
      return `/tag/${node.slug.current}`;
    case 'article':
      // console.log(node);
      return `/article/${node.slug.current}`;
    default:
      return `/${node.slug.current}`;
  }
};

export const animateNumber = (start, end, duration) => {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};

// inspired by https://github.com/GoogleChrome/workbox/blob/3d02230f0e977eb1dc86c48f16ea4bcefdae12af/packages/workbox-core/src/_private/logger.ts

const styles = [
  `background: rebeccapurple`,
  `border-radius: 0.5em`,
  `color: white`,
  `font-weight: bold`,
  `padding: 2px 0.5em`,
].join(`;`);

export function debugLog(...args) {
  console.debug(`%cgatsby`, styles, ...args);
}
