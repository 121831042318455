import React, { useEffect, createContext, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Header from './Header';
import Footer from './Footer';
import ImageExpand from './ui/ImageExpand';
// import ScrollToTop from './ui/ScrollToTop';

const WrapperContext = createContext({});

const duration = 0.35;

const variants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    y: -10,
    transition: {
      duration: duration,
    },
  },
};

const Layout = ({ children, pageContext, location }) => {
  const settings = {};
  // console.log(location);
  useEffect(() => {
    _format();
    window.addEventListener('resize', _format);
    return () => {
      window.removeEventListener('resize', _format);
    };
  }, []);

  const _format = () => {
    document.documentElement.style.setProperty('--app-height', window.innerHeight + 'px');

    const headerBounding = document.querySelector('header').getBoundingClientRect();

    document.documentElement.style.setProperty('--header-height', headerBounding.height + 'px');

    const footerBounding = document.querySelector('footer').getBoundingClientRect();

    document.documentElement.style.setProperty('--footer-height', footerBounding.height + 'px');

    const containerBounding = document
      .querySelector('.design-system .container > .inner')
      .getBoundingClientRect();

    document.documentElement.style.setProperty('--container-width', containerBounding.width + 'px');
  };

  return (
    <WrapperContext.Provider value={{ settings, location, pageContext }}>
      <div id="page">
        <div className="design-system fixed w-full opacity-0- pointer-events-none">
          <div className="bg-10 hover:bg-10-60 active:bg-10-35"></div>
          <div className="bg-17 hover:bg-17-60 active:bg-17-35"></div>
          <div className="bg-18 hover:bg-18-60 active:bg-18-35"></div>
          <div className="bg-19 hover:bg-19-60 active:bg-19-35"></div>
          <div className="h-_sm md:h-sm"></div>
          <div className="h-_md md:h-md"></div>
          <div className="h-_lg md:h-lg"></div>
          <div className="h-_xl md:h-xl"></div>
          <div className="h-_xxl md:h-xxl"></div>
          <div className="container">
            <div className="inner"></div>
          </div>
        </div>
        <Header />
        <AnimatePresence>
          <motion.div
            key={location.pathname}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            // ref={scrollRef}
          >
            <main>{children}</main>
          </motion.div>
        </AnimatePresence>
        <Footer />
        <ImageExpand selector=".modules .is-expandable" />
      </div>
    </WrapperContext.Provider>
  );
};

export { WrapperContext, Layout };

export default function useWrapperContext() {
  return useContext(WrapperContext);
}
