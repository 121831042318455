import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import TnPlusLogo from '../images/tnplus-logo.inline.svg';
import { _localizeField } from '../core/utils';
import SanityTexte from './SanityTexte';
import { _getSocialByName } from '../core/icons';
import ScrollToTop from './ui/ScrollToTop';

const query = graphql`
  query {
    sanityFooter {
      contact {
        ...localeBlockContent
      }
      social {
        label {
          ...localeString
        }
        link
      }
    }
  }
`;

const Footer = () => {
  const {
    sanityFooter: { social, contact },
  } = useStaticQuery(query);

  return (
    <footer className="py-md md:py-lg md:text-base overflow-hidden ">
      <div className="container">
        <div className="row justify-end md:justify-start">
          <div className="col-xs-6 col-md-5">
            <div className="site-name">
              <Link to="/" title="tn+">
                <TnPlusLogo />
              </Link>
            </div>
          </div>
          <div className="col-xs-6 col-md-2">
            <div className="mb-_md md:mb-0">
              <h3 className="mb-xs text-primary-50">Réseaux</h3>
              <ul>
                {social.map((li, i) => (
                  <li key={i}>
                    <a href={li.link} target="_blank" rel="noopener noreferrer">
                      <span className={_getSocialByName(li.label.fr.toLowerCase())}></span>
                      <span className="pl-xs">{_localizeField(li.label)}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-xs-6 col-md-5">
            <h3 className="mb-xs text-primary-50">Contact</h3>
            <SanityTexte input={contact} />
          </div>
        </div>
        <div className="row end-xs">
          <div className="col-md-7 col-xs-12">
            <div className="text-left pt-sm md:pt-lg text-primary-40 font-secondary text-_sm md:text-sm credits ">
              TN+ Paysagistes – © 2022
              <br />
              Conception graphique :{' '}
              <a href="https://travaux-pratiques.fr/" target="_blank" rel="noopener noreferrer">
                Travaux-Pratiques
              </a>{' '}
              / Développement :{' '}
              <a href="https://ahmedghazi.com/" target="_blank" rel="noopener noreferrer">
                Ahmed Ghazi
              </a>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </footer>
  );
};

export default Footer;
