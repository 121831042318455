//const website = require('./website')

module.exports = {
  fr: {
    default: true,
    label: 'Fr',
    path: 'fr',
    locale: 'fr',
    siteLanguage: 'fr',
    ogLang: 'fr_FR',
    projects: 'Projets',
    about: 'À propos',
    contact: 'Contact',
    type: 'Type',
    nature: 'Nature',
    surface: 'Superficie',
    capacity: "Capacité d'accueil",
    nextProjects: 'Projets suivants',
    ctaProjets: 'Voir tous les projets',
    news: 'Actualité',
  },
  en: {
    default: false,
    label: 'En',
    path: 'en',
    locale: 'en',
    siteLanguage: 'en',
    ogLang: 'en_GB',
    projects: 'Projects',
    about: 'About',
    contact: 'Contact',
    nextProjects: 'Next projects',
    ctaProjets: 'View all projects',
    news: 'News',
  },
};
