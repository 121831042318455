import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import { _linkResolver, _localizeField } from '../core/utils';
import { Link } from 'gatsby';
import clsx from 'clsx';

const SanityTexte = ({ input }) => {
  // console.log(input);
  const serializers = {
    types: {
      block(props) {
        // console.log(props.node.style)
        switch (props.node.style) {
          case 'h2':
            return <h2>{props.children}</h2>;
          case 'text-lg':
            return <p className="text-_lg md:text-lg mb-xl">{props.children}</p>;
          case 'text-md':
            return <p className="text-md">{props.children}</p>;
          case 'text-sm':
            return <p className="text-sm">{props.children}</p>;
          default:
            return <p>{props.children}</p>;
        }
      },
      linkPill(props) {
        // console.log(props);
        return (
          <Link
            to={_linkResolver(props.node.link)}
            className={clsx(
              'pill',
              `bg-${props.node.background} hover:bg-${props.node.background}-60 active:bg-${props.node.background}-35`
            )}
          >
            {_localizeField(props.node.label)}
          </Link>
        );
      },
    },

    marks: {
      sup: ({ children }) => <sup>{children}</sup>,

      link: ({ mark, children }) => {
        const { blank, href } = mark;
        return blank ? (
          <a href={href} target="_blank" rel="noopener, noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
      internalLink: ({ mark, children }) => {
        const { href } = mark;
        return (
          <a href={href} target="_blank" rel="noopener, noreferrer">
            {children}
          </a>
        );
      },
      // linkPill: ({ mark, children }) => {
      //   // const { href } = mark;
      //   console.log(mark);
      //   return 'link pill';
      // },
    },
  };

  // const texte = _localizeField(input);
  // console.log(texte);
  return (
    <div className="texte">
      <PortableText blocks={_localizeField(input)} serializers={serializers} />
    </div>
  );
};

export default SanityTexte;
