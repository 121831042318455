import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import TnPlusLogo from '../images/tnplus-logo.inline.svg';

import Burger from './ui/Burger';
import MenuOverlay from './MenuOverlay';
import { useScroll } from '../hooks/useScroll';
import clsx from 'clsx';
import { subscribe, unsubscribe } from 'pubsub-js';

const query = graphql`
  query {
    sanityHeader {
      # siteName
      nav {
        _type
        label {
          ...localeString
        }
        link {
          ... on SanityPage {
            slug {
              current
            }
            _type
          }
          ... on SanityProjets {
            _type
            slug {
              current
            }
          }
        }
      }
      adresse: _rawAdresse(resolveReferences: { maxDepth: 10 })
      contact: _rawContact(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Header = () => {
  const {
    sanityHeader: { nav, adresse, contact },
  } = useStaticQuery(query);

  const [hide, setHide] = useState(false);
  const { scrollY } = useScroll();

  const _update = () => {
    if (scrollY > 200) {
      setHide(true);
    } else {
      setHide(false);
    }

    // clearTimeout(isScrolling);
    // isScrolling = setTimeout(() => {
    //   clearTimeout(isScrolling);
    //   // _update();
    //   console.log(scrollY);
    //   if (scrollY < 100) {
    //     setHide(false);
    //   }
    // }, 500);
  };

  useEffect(() => {
    const tokenR = subscribe('ROUTE_UPDATE', (e) => {
      // console.log(e);
      setTimeout(() => {
        _update();
      }, 240);
    });
    const tokenB = subscribe('BURGER', (e, d) => {
      // console.log(e, d);
      setHide(!d);
    });
    return () => {
      unsubscribe(tokenR);
      unsubscribe(tokenB);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _update();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY, setHide]);

  // console.log(hide);
  return (
    <header
      className={clsx(
        'fixed top-0 w-full z-50 pt-_sm md:pt-md pb-sm ',
        // belowHero ? 'is-below-hero' : ''
        hide ? 'hide-logo' : ''
      )}
    >
      <div className="container z-20">
        <div className="flex justify-between">
          <div className="site-name ">
            <Link to="/" className="pointer-events-auto" title="tn+">
              <TnPlusLogo />
            </Link>
          </div>
          <Burger />
        </div>
      </div>

      <MenuOverlay menu={nav} adresse={adresse} contact={contact} />
    </header>
  );
};

export default Header;
