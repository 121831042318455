import React from 'react';
import clsx from 'clsx';
import useLocale from '../../contexts/LocaleWrapper';
// import { publish } from 'pubsub-js';
const locales = require('../../../config/i18n');

const LocaleSwitcher = () => {
  const { locale, dispatch } = useLocale();

  const _onClick = (_locale) => {
    // alert('locale change');
    dispatch(_locale);
    // publish('BURGER.CLOSE');
  };

  const renderLanguages = () => {
    const languages = Object.values(locales).map((li, key) => {
      const isActive = li.locale === locale;
      // const path = li.default ? `/` : `/${li.path}`;
      return (
        <li key={key} className="btn ">
          <button
            onClick={() => _onClick(li.locale)}
            className={clsx(
              'chip uppercase text-secondary md:hover:bg-primary-10 active:bg-primary-10 has-backdrop-filter',
              isActive ? 'is-active bg-primary' : 'bg-primary-20'
            )}
          >
            <span>{li.label}</span>
          </button>
        </li>
      );
    });
    return languages;
  };

  return (
    <ul className="locale-switcher flex font-secondary text-_sm md:text-sm pt-_sm md:pt-0">
      {renderLanguages()}
    </ul>
  );
};

export default LocaleSwitcher;
