export const _getWeatherByCode = (code) => {
  switch (true) {
    case code > 199 && code < 233:
      //Group 2xx: Thunderstorm
      return '11d';
    case code > 299 && code < 322:
      //Group 3xx: Drizzle
      return '09d';
    case code > 499 && code < 532:
      //Group 5xx: Rain
      return '09d';
    case code > 599 && code < 623:
      //Group 6xx: Snow
      return '09d';
    case code > 700 && code < 782:
      //Group 7xx: Atmosphere
      return '09d';
    case code === 800:
      return '01d';
    case code === 801:
      return '02d';
    case code > 801 && code < 805:
      return '03d';

    default:
      return `01d`;
  }
};

export const _getSocialByName = (name) => {
  switch (name) {
    case 'instagram':
      return 'icon-instagram';
    case 'facebook':
      return 'icon-facebook';
    case 'linkedin':
      return 'icon-linkedin';
    default:
      return ``;
  }
};
