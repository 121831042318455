exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/Article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-projet-jsx": () => import("./../../../src/templates/Projet.jsx" /* webpackChunkName: "component---src-templates-projet-jsx" */),
  "component---src-templates-projets-jsx": () => import("./../../../src/templates/Projets.jsx" /* webpackChunkName: "component---src-templates-projets-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/Tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

