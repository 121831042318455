import React, { useEffect, useState } from 'react';
import { subscribe, unsubscribe } from 'pubsub-js';
import clsx from 'clsx';
import { motion, useAnimation } from 'framer-motion';
import { _linkResolver, _localizeField } from '../core/utils';
import { Link } from 'gatsby';
import LocaleSwitcher from './ui/LocaleSwitcher';
import SanityTexte from './SanityTexte';
import useDeviceDetect from '../hooks/useDeviceDetect';

const MenuOverlay = ({ menu, adresse, contact }) => {
  const control = useAnimation();
  // const duration = 0.5;
  // const ease = "easeInOutCubic";
  // const ease = 'ease';
  const [show, setShow] = useState(false);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    const token = subscribe('BURGER', (e, d) => {
      // console.log(d)
      setShow(d);
    });
    return () => unsubscribe(token);
  }, []);

  useEffect(() => {
    if (show) {
      setTimeout(() => setPlay(true), 250);
    } else {
      setPlay(false);
    }
  }, [show]);

  useEffect(() => {
    if (play) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, play]);

  const { isMobile } = useDeviceDetect();
  return (
    <div
      className={clsx(
        'nav-overlay fixed left-0- inset-0 transition-opacity duration-500 px-xl- py-_sm md:py-md overflow-hidden z-10',
        show ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      )}
    >
      <div className="bg absolute inset-0 pointer-events-none z-0 bg-primary-20"></div>
      <div className="inner h-full  z-10">
        <div className="absolute top-header-height md:top-0 w-full md:text-base text-secondary contacts">
          <div className="container">
            <div
              className="row custom-gutter"
              style={{
                '--custom-gutter': isMobile ? 'var(--space-1e)' : 'var(--space-xs)',
              }}
            >
              <div className="col-md-2 hidden-sm"></div>
              <div className="col-lg-3 col-md-4 mb-sm col-xs-12">
                <motion.div
                  variants={{
                    visible: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        // delay: 0.5,
                        duration: 0.4,
                      },
                    },
                    hidden: {
                      opacity: 0,
                      y: -10,
                      transition: {
                        delay: 0,
                        duration: 0.2,
                      },
                    },
                  }}
                  initial="hidden"
                  animate={control}
                  className=""
                >
                  <SanityTexte input={adresse} />
                </motion.div>
              </div>
              <div className="col-lg-3 col-md-4">
                <motion.div
                  variants={{
                    visible: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: 0.1,
                        duration: 0.4,
                      },
                    },
                    hidden: {
                      opacity: 0,
                      y: -10,
                      transition: {
                        delay: 0,
                        duration: 0.2,
                      },
                    },
                  }}
                  initial="hidden"
                  animate={control}
                  className=""
                >
                  <SanityTexte input={contact} />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <div className="container ">
            <div className="flex flex-col md:flex-row justify-between md:items-end">
              <nav>
                <ul>
                  {menu.map((li, i) => (
                    <li key={i}>
                      <motion.div
                        variants={{
                          visible: {
                            opacity: 1,
                            y: 0,
                            transition: {
                              // delay: i / 10,
                              duration: 0.4,
                            },
                          },
                          hidden: {
                            opacity: 0,
                            y: -10,
                            transition: {
                              delay: 0,
                              duration: 0.2,
                            },
                          },
                        }}
                        initial="hidden"
                        animate={control}
                        className="dddd"
                      >
                        <Link
                          to={_linkResolver(li.link)}
                          className="text-_xl md:text-xl text-secondary hover:text-secondaryHover leading-none font-extralight"
                        >
                          {_localizeField(li.label)}
                        </Link>
                      </motion.div>
                    </li>
                  ))}
                </ul>
              </nav>
              <motion.div
                variants={{
                  visible: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.5,
                      duration: 0.4,
                    },
                  },
                  hidden: {
                    opacity: 0,
                    y: -10,
                    transition: {
                      delay: 0,
                      duration: 0.2,
                    },
                  },
                }}
                initial="hidden"
                animate={control}
                className="dddd"
              >
                <LocaleSwitcher />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuOverlay;
